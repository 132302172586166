import React, { type ReactElement, useContext } from 'react'
import { Button } from '@stuller/stullercom/ui'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { MenuCartContext } from './MenuCartContext'
import { CartPopoverContext } from './CartPopoverContext'
import { CartCheckoutError } from './CartCheckoutError'
import { ShippingCountdown } from '@stuller/stullercom/feat/shipping-countdown'

/**
 * The button that links to the checkout page
 */
function CartCheckoutButton (): ReactElement | null {
  const { hasScheduledOrder } = useContext(MenuCartContext)
  const { cart } = useContext(CartPopoverContext)
  const { isShowcase, authUser, hasRole } = useAuth()
  const cartLines = cart.loading
    ? cart.previousData?.cart?.items
    : cart.data?.cart?.items
  const cartErrors = cart.loading
    ? cart.previousData?.cart?.errors
    : cart.data?.cart?.errors
  const shippingCountdown = cart.loading
    ? cart.previousData?.cart?.shippingCountdown
    : cart.data?.cart?.shippingCountdown

  const showCheckoutButton = !hasScheduledOrder &&
    cartLines != null &&
    cartLines?.length > 0 &&
    (authUser == null || hasRole('Can Checkout')) &&
    (!isShowcase || (hasRole('Order Requires Approval') || hasRole('Order Requires Approval Bypass Checkout')))

  if (!showCheckoutButton) {
    return null
  }

  return (
    <div className='px-6 mb-4'>
      {shippingCountdown != null && <ShippingCountdown countdown={shippingCountdown} />}
      {cartErrors != null && cartErrors?.length > 0 && <CartCheckoutError />}
      <Button
        href='/checkout'
        tag='a'
        color='success'
        pill
        className='w-100'
        disabled={cartErrors != null && cartErrors?.length > 0}
        data-test='checkout-now-button'
      >
        Checkout Now
      </Button>
    </div>
  )
}

export {
  CartCheckoutButton
}
