import { type ElementType, forwardRef, type ReactNode } from 'react'
import clsx from 'clsx'
import { type HeadingTag } from '../../..'

export interface AlertHeadingProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of the loading indicator
   */
  children?: ReactNode
  /**
   * Defined element type
   */
  tag?: HeadingTag | 'div'
}

/**
 * Alert header component
 */
const AlertHeading = forwardRef<HTMLHeadElement | HTMLDivElement, AlertHeadingProps>(({
  className,
  children,
  tag: Tag = 'h3' as ElementType,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('alert-heading', className)

  return (
    <Tag
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </Tag>
  )
})
AlertHeading.displayName = 'AlertHeading'

export {
  AlertHeading
}
