import Router from 'next/router'
import { memo, useCallback, useEffect, useState } from 'react'
import * as NProgress from 'nprogress'

const COLOR = 'var(--bs-cyan-500)'
const Css = (
  <style jsx global>{`
    #nprogress {
      pointer-events: none;
    }

    #nprogress .bar {
      background: ${COLOR};
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
    }

    #nprogress .peg {
      display: block;
      position: absolute;
      right: 0px;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px ${COLOR}, 0 0 5px ${COLOR};
      opacity: 1;
      transform: rotate(3deg) translate(0px, -4px);
    }
  `}
  </style>
)

const START_POSITION = 0.3
const STOP_DELAY = 200
const SHOW_ON_SHALLOW = true
const OPTIONS: Partial<NProgress.NProgressOptions> = {
  minimum: 0.08,
  easing: 'ease',
  speed: 200,
  trickleSpeed: 200,
  showSpinner: false
}

/**
 * Page progress bar shown at the top of the page
 */
const PageProgress = memo(() => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const routeChangeStart = useCallback((_: string, { shallow }: { shallow: boolean }): void => {
    if (!shallow || SHOW_ON_SHALLOW) {
      NProgress.set(START_POSITION)
      NProgress.start()
    }
  }, [])

  const routeChangeEnd = useCallback((_: string, { shallow }: { shallow: boolean }): void => {
    if (!shallow || SHOW_ON_SHALLOW) {
      if (timer != null) {
        clearTimeout(timer)
      }
      setTimer(setTimeout(() => {
        NProgress.done(true)
      }, STOP_DELAY))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const routeChangeError = useCallback((_err: Error, _url: string, { shallow }: { shallow: boolean }): void => {
    if (!shallow || SHOW_ON_SHALLOW) {
      if (timer != null) {
        clearTimeout(timer)
      }
      setTimer(setTimeout(() => {
        NProgress.done(true)
      }, STOP_DELAY))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    NProgress.configure(OPTIONS)
    Router.events.on('routeChangeStart', routeChangeStart)
    Router.events.on('routeChangeComplete', routeChangeEnd)
    Router.events.on('routeChangeError', routeChangeError)

    return () => {
      Router.events.off('routeChangeStart', routeChangeStart)
      Router.events.off('routeChangeComplete', routeChangeEnd)
      Router.events.off('routeChangeError', routeChangeError)
    }
  }, [routeChangeStart, routeChangeEnd, routeChangeError])

  return Css
})
PageProgress.displayName = 'PageProgress'

export {
  PageProgress
}
