import { type Auth } from '@stuller/stullercom/feat/auth'
import { trackEvent } from '../track'
import type { ItemTracking, ProductOrGroupTracking } from './types'

type ProductGroupTracking = Extract<ProductOrGroupTracking, { __typename?: 'ProductGroup' }>
type ProductTracking = Extract<ProductOrGroupTracking, { __typename?: 'Product' }>
type SerializedProductTracking = Extract<ProductOrGroupTracking, { __typename?: 'SerializedProduct' }>
type ConfigurationTracking = Extract<ProductOrGroupTracking, { __typename?: 'Configuration' }>

/**
 * Map Product data to ItemTracking object
 */
function productGroupTrackingMapper (tracking: ProductGroupTracking, auth: Auth): ItemTracking {
  return {
    item_id: tracking.code,
    item_variant: null,
    item_name: tracking.title,
    item_brand: null,
    item_category: null,
    item_category2: null,
    item_category3: null,
    item_category4: null,
    item_category5: null,
    currency: null,
    discount: null,
    price: null,
    quantity: 1,
    config_id: null,
    serial_number: null,
    coupon: null,
    index: tracking.index ?? 0,
    item_list_id: tracking.item_list_id ?? null,
    item_list_name: null
  }
}

/**
 * Map Product data to ItemTracking object
 */
function productTrackingMapper (tracking: ProductTracking, auth: Auth): ItemTracking {
  const { isShowcase } = auth
  const price = isShowcase
    ? null
    : tracking.price?.roundedPerUnit.discount?.originalPrice.valueInUsd ??
        tracking.price?.roundedPerUnit.total.valueInUsd ??
        null

  return {
    item_id: tracking.defaultProductGroup?.code ?? tracking.series,
    item_variant: tracking.itemNumber,
    item_name: tracking.title,
    item_brand: tracking.brand ?? null,
    item_category: tracking.merchandiseHierarchy?.segment2 ?? null,
    item_category2: tracking.merchandiseHierarchy?.segment3 ?? null,
    item_category3: tracking.merchandiseHierarchy?.segment4 ?? null,
    item_category4: tracking.merchandiseHierarchy?.segment5 ?? null,
    item_category5: tracking.merchandiseHierarchy?.segment6 ?? null,
    currency: price != null ? 'USD' : null,
    discount: price != null ? tracking.price?.roundedPerUnit.discount?.value.valueInUsd ?? 0 : null,
    price,
    quantity: 1,
    config_id: null,
    serial_number: null,
    coupon: null,
    index: tracking.index ?? 0,
    item_list_id: tracking.item_list_id ?? null,
    item_list_name: null
  }
}

/**
 * Map Serialized Product data to ItemTracking object
 */
function serializedProductTrackingMapper (tracking: SerializedProductTracking, auth: Auth): ItemTracking {
  const { isShowcase } = auth
  const price = isShowcase
    ? null
    : tracking.serializedProduct.price?.roundedPerUnit.discount?.originalPrice.valueInUsd ??
        tracking.serializedProduct.price?.roundedPerUnit.total.valueInUsd ??
        null

  return {
    item_id: tracking.defaultProductGroup?.code ?? tracking.serializedProduct.series,
    item_variant: tracking.itemNumber,
    item_name: tracking.title,
    item_brand: tracking.brand ?? null,
    item_category: tracking.merchandiseHierarchy?.segment2 ?? null,
    item_category2: tracking.merchandiseHierarchy?.segment3 ?? null,
    item_category3: tracking.merchandiseHierarchy?.segment4 ?? null,
    item_category4: tracking.merchandiseHierarchy?.segment5 ?? null,
    item_category5: tracking.merchandiseHierarchy?.segment6 ?? null,
    currency: price != null ? 'USD' : null,
    discount: price != null ? tracking.serializedProduct.price?.roundedPerUnit.discount?.value.valueInUsd ?? 0 : null,
    price,
    quantity: 1,
    config_id: null,
    serial_number: Number(tracking.serialNumber),
    coupon: null,
    index: tracking.index ?? 0,
    item_list_id: tracking.item_list_id ?? null,
    item_list_name: null
  }
}

/**
 * Map Configuration data to ItemTracking object
 */
function configurationTrackingMapper (tracking: ConfigurationTracking, auth: Auth): ItemTracking {
  const { isShowcase } = auth
  const price = isShowcase
    ? null
    : tracking.configurationProduct.price?.roundedPerUnit.discount?.originalPrice.valueInUsd ??
        tracking.configurationProduct.price?.roundedPerUnit.total.valueInUsd ??
        null

  return {
    item_id: tracking.defaultProductGroup?.code ?? tracking.configurationProduct.series,
    item_variant: tracking.itemNumber,
    item_name: tracking.title,
    item_brand: tracking.brand ?? null,
    item_category: tracking.merchandiseHierarchy?.segment2 ?? null,
    item_category2: tracking.merchandiseHierarchy?.segment3 ?? null,
    item_category3: tracking.merchandiseHierarchy?.segment4 ?? null,
    item_category4: tracking.merchandiseHierarchy?.segment5 ?? null,
    item_category5: tracking.merchandiseHierarchy?.segment6 ?? null,
    currency: price != null ? 'USD' : null,
    discount: price != null ? tracking.configurationProduct.price?.roundedPerUnit.discount?.value.valueInUsd ?? 0 : null,
    price,
    quantity: 1,
    config_id: Number(tracking.id),
    serial_number: null,
    coupon: null,
    index: tracking.index ?? 0,
    item_list_id: tracking.item_list_id ?? null,
    item_list_name: null
  }
}

/**
 * Helper Method to map Product and ProductGroup data to ItemTracking object
*/
function productOrGroupTrackingMapper (tracking: ProductOrGroupTracking, auth: Auth): ItemTracking | null {
  switch (tracking.__typename) {
    case 'ProductGroup': {
      return productGroupTrackingMapper(tracking, auth)
    }
    case 'Product': {
      return productTrackingMapper(tracking, auth)
    }
    case 'SerializedProduct': {
      return serializedProductTrackingMapper(tracking, auth)
    }
    case 'Configuration': {
      return configurationTrackingMapper(tracking, auth)
    }
    default: {
      return null
    }
  }
}

/**
 * Track view item list event
 */
function trackViewItemList (trackings: ProductOrGroupTracking[], auth: Auth): void {
  trackEvent('view_item_list', {
    items: trackings.map(tracking => productOrGroupTrackingMapper(tracking, auth))
      .filter(i => i != null)
  })
}

/**
 * Track select item event
 */
function trackSelectItem (tracking: ProductOrGroupTracking, auth: Auth): void {
  trackEvent('select_item', {
    items: [productOrGroupTrackingMapper(tracking, auth)]
      .filter(i => i != null)
  })
}

export {
  trackViewItemList,
  trackSelectItem,
  productOrGroupTrackingMapper
}
