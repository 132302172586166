import { type ReactElement, memo } from 'react'
import Link from 'next/link'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'

/**
 * Shows the stuller first banner
 */
const StullerFirstBanner = memo((): ReactElement | null => {
  const { authUser, isShowcase, isSterling } = useAuth()

  if (authUser?.user?.isStullerFirstEnrolled !== true || isShowcase || isSterling) {
    return null
  }

  return (
    <div className='bg-stuller-first-gold-300 d-print-none'>
      <div className='container-xxxl py-1 py-md-2 text-center'>
        <div className='row align-items-center justify-content-center ls-2'>
          <Link className='text-decoration-none text-white' href='/stullerfirst'>
            <div className='col-auto text-uppercase fs-6 cursor-pointer'>
              <span>Welcome,</span>
              <span className='fw-bold px-1'>Stuller First<sup>&reg;</sup></span>
              <span className='px-1'>Member.</span>
              <span className='text-decoration-underline fw-normal px-1'>View savings and benefits</span>
            </div>
          </Link>
          <CmsContent contentContainerName='stuller-first-header' skipLoading noRefetch />
        </div>
      </div>
    </div>
  )
})
StullerFirstBanner.displayName = 'StullerFirstBanner'

export {
  StullerFirstBanner
}
