import { type CSSProperties, forwardRef, type MouseEventHandler, type ReactNode, useMemo } from 'react'
import clsx from 'clsx'
import { Button, type ThemeColor } from '../../..'

export interface AlertProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of the loading indicator
   */
  children?: ReactNode
  /**
   * Theme color for alert
   */
  color?: ThemeColor
  /**
   * Makes the alert background/border transparent
   */
  transparent?: boolean
  /**
   * Size of alert non-default (adjusts padding)
   */
  size?: 'sm'
  /**
   * Indicates to show the alert
   */
  show?: boolean
  /**
   * Handle when toggled from within the alert (if sent, shows close button that calls this)
   */
  onToggle?: MouseEventHandler<HTMLButtonElement> & MouseEventHandler<HTMLAnchorElement>
  /**
   * Override close button or null
   */
  close?: ReactNode
}

/**
 * Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages.
 *
 * Alerts only provide the main layout/styling and a few inner components (`AlertHeading`, `AlertIcon`). For inner alert layout, use `row`, `col`, and flex helpers (see example stories).
 *
 * For easy alert state management, use `useToggle`.
 */
const Alert = forwardRef<HTMLDivElement, AlertProps>(({
  className,
  children,
  color = 'secondary',
  transparent = false,
  size,
  show = true,
  onToggle,
  close,
  ...otherAttributes
}, ref) => {
  const closeButton = useMemo(() => {
    if (close != null) {
      return close
    } else if (onToggle != null) {
      return <Button onClick={onToggle} close />
    }

    return null
  }, [close, onToggle])
  const classNames = clsx(
    `alert alert-${color}`,
    transparent && 'alert-transparent',
    size != null && `alert-${size}`,
    closeButton != null && 'alert-dismissible',
    className
  )

  if (!show) {
    return null
  }

  return (
    <div
      className={classNames}
      ref={ref}
      role='alert'
      {...otherAttributes}
    >
      {children}
      {closeButton}
    </div>
  )
})
Alert.displayName = 'Alert'

export {
  Alert
}
