import { type ReactElement, useRef } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'

/**
 * A component to show the shipping countdown modal with info about eligible products
 */
function EligibleProductsModal (): ReactElement {
  const [isOpen, toggleIsOpen] = useToggle()
  const portalRef = useRef(typeof window !== 'undefined' ? document.body : undefined)

  return (
    <>
      <Button onClick={toggleIsOpen} color='anchor'>
        <FontAwesomeIcon className='fs-3 me-2 text-secondary' icon={fas.faCircleQuestion} />
        <span className='fw-light text-decoration-underline'>Eligible Items</span>
      </Button>
      <Modal isOpen={isOpen} onToggle={toggleIsOpen} portalRef={portalRef}>
        <ModalHeader onToggle={toggleIsOpen}>
          Eligible Items
        </ModalHeader>
        <ModalBody>
          For items to be eligible to ship with your recent order, they must match multiple criteria from that previous order.
          <span className='fw-bold'> Eligibility is not guaranteed.</span>
          <br />
          <br />
          <span className='fw-bold'>Requirements for items to ship with your recent order:</span>
          <ul>
            <li>Items must ship to the <span className='fw-bold'>same address</span> as your previous order</li>
            <li>Items must use the same <span className='fw-bold'>shipping type</span> as your previous order (air or ground)</li>
            <li>Items must be paid for via the <span className='fw-bold'>same payment type</span> (COD or non-COD)</li>
            <li>If items are to ship with a Saturday delivery order, the Saturday delivery shipping method must be selected at checkout</li>
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={toggleIsOpen}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export {
  EligibleProductsModal
}
