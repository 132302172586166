import { type ReactElement } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { MobilePopover } from './MobilePopover'

interface MobileMenuProps {
  /**
   * Reference element for the popover
   */
  reference: HTMLDivElement | null
}

/**
 * Menu component
 */
function MobileMenu ({ reference }: MobileMenuProps): ReactElement | null {
  const [open, handleToggle, handleOpenChange] = useToggle(false)
  const { isShowcase } = useAuth()
  const classNames = clsx(
    'row align-items-center gx-3 py-1 ps-2 cursor-pointer fs-7',
    isShowcase ? 'bg-gray-700 rounded pe-2 text-white' : 'text-blue-400'
  )
  const iconClassNames = clsx(
    'col-auto',
    isShowcase ? 'fs-2 text-gray-500' : 'fs-1 text-cyan-600'
  )

  return (
    <>
      <div className={classNames} onClick={handleToggle}>
        <div className='col-auto py-2'>
          <FontAwesomeIcon icon={fas.faBars} className={iconClassNames} />
        </div>
        <div className='col-auto ws-1 pb-1'>
          MENU
        </div>
      </div>
      <MobilePopover
        open={open}
        onOpenChange={handleOpenChange}
        reference={reference}
      />
    </>
  )
}

export {
  MobileMenu
}
