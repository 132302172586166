import { type ReactElement, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Cookies from 'js-cookie'
import { Button } from '@stuller/stullercom/ui'

const CMS_EDITING_COOKIE_NAME = 'CMS_Editing_Cookie'

/**
 * Shows the CMS mode toggle button and uses the CMS editing cookie
 */
function CmsEdit (): ReactElement {
  const router = useRouter()
  const [cmsMode, setCmsMode] = useState(false)

  /**
   * Handle CMS Edit
   */
  function handleToggleCmsMode (): void {
    Cookies.set(CMS_EDITING_COOKIE_NAME, (!cmsMode).toString(), { secure: true })
    router.reload()
  }

  /**
   * On component load, read cookie and set defaults
   */
  useEffect(() => {
    setCmsMode(Cookies.get(CMS_EDITING_COOKIE_NAME)?.toLowerCase() === 'true')
  }, [])

  return (
    <>
      <Button type='submit' color='anchor-dark' className='text-decoration-none' onClick={handleToggleCmsMode}>
        {cmsMode ? 'Disable' : 'Enable'} CMS Mode
      </Button>
      <span className='link-dark'> | </span>
      <Link href='/cms' className='link-dark text-decoration-none'>CMS</Link>
    </>
  )
}

export {
  CmsEdit
}
