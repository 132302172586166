import fetch from 'cross-fetch'
import { type StullerEvents } from '@stuller/stullercom/feat/stuller-events'
import { trackEvent } from '@stuller/stullercom/feat/google-tag-manager'

type FormBuilderSubmission = StullerEvents['form-builder-submission']
type FormJson = Record<string, any>
type ContactFormEls = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
interface FormDataCms {
  ContentContainerId: number
  ContentId: number
  Title: string
  AnalyticsName: string
  AnalyticsGenerateLead: boolean
  AnalyticsValue: number
  AnalyticsCurrency: string
}

/**
 * Gets the elements needed for submitting form builder
 */
function getFormElements (target: FormBuilderSubmission['target']): {
  formEl: HTMLFormElement | null
  formInnerEl: HTMLElement | null
  contactFormEls: NodeListOf<ContactFormEls> | null
  messageEl: HTMLElement | null
} {
  const formEl = target.closest('form')
  const formInnerEl: HTMLElement | null = formEl?.querySelector('.fb-form') ?? null
  const contactFormEls = formEl?.querySelectorAll<ContactFormEls>('.contact-form input, .contact-form select, .contact-form textarea') ?? null
  const messageEl: HTMLElement | null = formEl?.querySelector('.fb-message') ?? null

  return {
    formEl,
    formInnerEl,
    contactFormEls,
    messageEl
  }
}

/**
 * Converts FormData from the form to JSON
 */
function getFormJson (formData: FormData, nullValue: any): FormJson {
  const json: FormJson = {}

  for (const [name, value] of formData) {
    if (name !== 'chooseAContact') {
      if (json[name] !== undefined) {
        if (json[name].push == null) {
          json[name] = [json[name]]
        }
        json[name].push(value ?? nullValue)
      } else {
        json[name] = value ?? nullValue
      }
    }
  }

  return json
}

/**
 * Updates the form message
 * Send HTML to show the message (defaults to error message)
 * Send the form inner element for success message and hide the element
 */
function updateMessage (messageEl: HTMLElement | null, html: string | null = null, formInnerEl?: HTMLElement | null): void {
  if (messageEl != null) {
    if (html != null) {
      messageEl.style.display = ''
      messageEl.innerHTML = html
      if (formInnerEl !== undefined) {
        messageEl.classList.add('alert-success')
        messageEl.classList.add('mb-0')
      } else {
        messageEl.classList.add('alert-danger')
      }
      if (formInnerEl != null) {
        formInnerEl.style.display = 'none'
      }
    } else {
      messageEl.style.display = 'none'
      messageEl.innerHTML = ''
      messageEl.classList.remove('alert-success')
      messageEl.classList.remove('mb-0')
      messageEl.classList.remove('alert-danger')
    }
  }
}

/**
 * Tracks a form event given the CMS data of the form
 */
function trackFormEvent (formDataCms: FormDataCms): void {
  if (formDataCms.AnalyticsName != null) {
    trackEvent('form_completed', {
      form_name: formDataCms.AnalyticsName,
      generate_lead: formDataCms.AnalyticsGenerateLead,
      value: formDataCms.AnalyticsValue,
      currency: formDataCms.AnalyticsCurrency
    })
  }
}

/**
 * Submits and updates form builder form
 */
async function submitFormBuilder ({ target, data = {}, callback }: FormBuilderSubmission): Promise<void> {
  // Get form elements
  const { formEl, formInnerEl, contactFormEls, messageEl } = getFormElements(target)
  if (formEl == null || !formEl.reportValidity()) {
    return
  }

  // Get form data and JSON
  const formData = new FormData(formEl)
  const formJson = getFormJson(formData, '')
  const formJsonNull = getFormJson(formData, null)
  const allJson = { ...formJson, ...data }

  // Get form data from CMS data attribute
  let formDataCms: FormDataCms | null = null
  if (formEl.dataset.cms != null) {
    try {
      formDataCms = JSON.parse(formEl.dataset.cms)
    } catch (error) {
      console.error('Error parsing form CMS data', error)
    }
  }

  // Create contact and CRM JSON
  let contactJson: FormJson | null = null
  const crmJson: FormJson = { ...data }
  const contactFormInputNames = Array.from(contactFormEls ?? []).map(i => i.name)
  for (const name of Object.keys(formJson)) {
    if (contactFormInputNames.includes(name)) {
      if (contactJson == null) {
        contactJson = {}
      }
      contactJson[name] = formJsonNull[name]
    } else {
      crmJson[name] = formJson[name]
    }
  }

  // Get message element and remove error/success classes
  updateMessage(messageEl)

  // Post form and handle response
  try {
    target.disabled = true
    const response = await fetch(formEl.action, {
      method: 'POST',
      body: new URLSearchParams({
        json: JSON.stringify(allJson),
        contact: JSON.stringify(contactJson),
        crmJson: JSON.stringify(crmJson)
      })
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    // Respond to result
    const result: Parameters<NonNullable<FormBuilderSubmission['callback']>>[0] = await response.json()
    if (result.success) {
      if (formDataCms != null) {
        trackFormEvent(formDataCms)
      }
      if (result.is_url === true) {
        callback?.(result)
        window.location.href = result.message
      } else {
        updateMessage(messageEl, result.message, formInnerEl)
      }
    } else {
      updateMessage(messageEl, result.message)
    }

    target.disabled = false
    callback?.(result)
  } catch (error) {
    target.disabled = false
    updateMessage(messageEl, 'We\'re sorry. We were unable to process your form at this time.')
  }
}

export {
  submitFormBuilder
}
