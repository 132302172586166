import { type ReactElement, useState } from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useGetShowcaseSettingsSetupQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { Alert, AlertIcon, Button, Loading, Progress, ProgressCircle, ProgressCirclePercent } from '@stuller/stullercom/ui'
import { useLock } from '@stuller/shared/util/react-hooks'
import { useRouter } from 'next/router'
import { ShowcaseCallbackRequests } from './ShowcaseCallbackRequests'

/**
 * Shown when the user is in NOT in showcase mode (inquiries, turn on button, etc.)
 */
function StullercomMode (): ReactElement {
  const { asPath } = useRouter()
  const [loginError, setLoginError] = useState<string | null>(null)
  const [redirecting, setRedirecting] = useState(false)
  const showcaseSettingsSetup = useGetShowcaseSettingsSetupQuery()
  const markupsComplete = showcaseSettingsSetup.data?.viewer?.showcaseSettings?.setup.markupsComplete === true
  const displayComplete = showcaseSettingsSetup.data?.viewer?.showcaseSettings?.setup.displayComplete === true
  const [percentComplete, timeToComplete] = markupsComplete && displayComplete ? [100, 0] : !markupsComplete && !displayComplete ? [0, 8] : [50, 3]
  const settingsComplete = percentComplete === 100

  const [handleTurnOnShowcaseMode, loginLoading] = useLock(async () => {
    setLoginError(null)

    try {
      const response = await fetch('/showcasemode/loginjson', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          returnUrl: encodeURIComponent(asPath)
        })
      })
      if (response.ok) {
        const data = await response.json()
        const serverErrors = data.errorMessages
        if (serverErrors == null || serverErrors.length === 0) {
          window.location.href = data.redirectUrl
          setRedirecting(true)
        } else {
          setLoginError(serverErrors[0].ErrorMessage as string | null)
        }
      } else {
        throw new Error()
      }
    } catch (error) {
      setLoginError('An unknown error occurred. Please try again later.')
    }
  })

  const loading = showcaseSettingsSetup.loading || loginLoading || redirecting
  const error = showcaseSettingsSetup.error != null ? 'Unable to get showcase settings.' : loginError

  return (
    <>
      {!loading && error != null && (
        <Alert color='danger' className='mb-4'>
          <div className='row align-items-center gy-3'>
            <div className='col-auto'>
              <AlertIcon>
                <FontAwesomeIcon icon={fas.faTriangleExclamation} />
              </AlertIcon>
            </div>
            <div className='col'>
              {error}
            </div>
          </div>
        </Alert>)}

      <ShowcaseCallbackRequests />

      <Loading loading={loading}>
        {!loading && !markupsComplete && (
          <div className='row align-items-center mb-4 px-4'>
            <div className='col-auto'>
              <FontAwesomeIcon icon={fas.faDesktop} className='text-primary' size='2xl' />
            </div>
            <div className='col fw-light'>
              You must complete <Link href='/showcasemarkups'>Markup Settings</Link> before turning on Showcase Mode
            </div>
          </div>)}

        <Button color='primary' className='w-100 mb-4' disabled={!markupsComplete} pill onClick={handleTurnOnShowcaseMode}>
          Turn On Showcase Mode
        </Button>

        <Button href='/myaccount/showcasesettings' tag='a' color='primary' className='w-100 mb-4' pill>
          Change Settings
        </Button>

        <Button href='/showcase-faq' tag='a' color='primary' className='w-100 mb-4' pill outline>
          Learn More
        </Button>

        {!showcaseSettingsSetup.loading && !settingsComplete && (
          <div>
            <div className='fw-bold text-center mb-3'>
              Setup Completion
            </div>
            <Progress className='mb-3 d-block'>
              <ProgressCircle value={percentComplete} backgroundColor='white' />
              <ProgressCirclePercent value={percentComplete} />
            </Progress>
            <div className='fw-light mb-3'>
              You can finish your setup in as little as <b>{timeToComplete} minutes</b>
            </div>
            <Button
              href={markupsComplete ? '/showcasesettings' : '/showcasemarkups'}
              tag='a'
              color='primary'
              className='w-100'
              pill
              outline
            >
              Finish Setup
            </Button>
          </div>)}
      </Loading>
    </>
  )
}

export {
  StullercomMode
}
