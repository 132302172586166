import { type ReactElement, useMemo, type MouseEvent, useContext, type CSSProperties } from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import { NotificationStatus, useUpdateNotificationStatusMutation } from '@stuller/stullercom/data-access/apollo-queries'
import { Loading } from '@stuller/stullercom/ui'
import { dayjs } from '@stuller/shared/util/core'
import { MessagesPopoverContext } from './MessagesPopoverContext'
import { MessageTitle } from './MessageTitle'
import { MessageReadIcon } from './MessageReadIcon'
import { NOTIFICATIONS_SETTINGS, NOTIFICATION_APP } from './constants'
import { type Notification } from './types'

interface MessageCardProps {
  /**
   * A single notification from the feed
   */
  notification: Notification
}

/**
 * A single message card in the messages list
 */
function MessageCard ({ notification }: MessageCardProps): ReactElement {
  const { unreadCount } = useContext(MessagesPopoverContext)
  const router = useRouter()
  const settings = NOTIFICATIONS_SETTINGS[notification.type]
  const classNames = clsx(
    'position-relative bg-white rounded border-start border-5 p-3 mb-2 user-select-none',
    notification.important
      ? 'border-danger'
      : `border-${settings.color}`,
    (notification.status === NotificationStatus.Unread || notification.metadata.url.trim() !== '') && 'cursor-pointer',
    notification.status === NotificationStatus.Read && 'opacity-40'
  )
  const styles: CSSProperties = {
    background: notification.important ? 'linear-gradient(to right, var(--bs-red-100), white 75%)' : `linear-gradient(to right, var(--bs-${settings.backgroundColor}), white 75% )`
  }
  const shortDate = useMemo(() => {
    const date = dayjs(notification.createdDate)

    return date.isToday() ? date.format('LT') : date.fromNow(true)
  }, [notification.createdDate])
  const longDate = useMemo(() => dayjs(notification.createdDate).format('llll'), [notification.createdDate])
  const [updateStatus, { loading }] = useUpdateNotificationStatusMutation({
    onError: () => {
      console.error('Could not update notification status')
    },
    update: (cache, { data }) => {
      if (data?.updateNotificationStatus?.notification == null || unreadCount.data?.viewer == null) {
        return
      }

      const adjust = data.updateNotificationStatus.notification.status === NotificationStatus.Read ? -1 : 1
      const importantAdjust = notification.important ? adjust : 0
      cache.modify<{ notificationsUnreadCount: NonNullable<NonNullable<typeof unreadCount['data']>['viewer']>['notificationsUnreadCount'] }>({
        id: cache.identify(unreadCount.data.viewer),
        fields: {
          notificationsUnreadCount (existing) {
            if ('__typename' in existing) {
              return {
                ...existing,
                total: existing.total + adjust,
                important: existing.important + importantAdjust
              }
            }

            return existing
          }
        }
      })
    }
  })

  /**
   * Handle clicking on the message card to navigate to the url and mark as read
   */
  async function handleCardClick (): Promise<void> {
    if (notification.status === NotificationStatus.Unread) {
      await updateStatus({
        variables: {
          input: {
            app: NOTIFICATION_APP,
            id: notification.id,
            status: NotificationStatus.Read
          }
        }
      })
    }

    if (notification.metadata.url.trim() !== '') {
      await router.push(notification.metadata.url)
    }
  }

  /**
   * Handle clicking on the read icon
   */
  async function handleIconClick (event: MouseEvent<HTMLDivElement>): Promise<void> {
    event.stopPropagation()

    void updateStatus({
      variables: {
        input: {
          app: NOTIFICATION_APP,
          id: notification.id,
          status: notification.status === NotificationStatus.Unread ? NotificationStatus.Read : NotificationStatus.Unread
        }
      }
    })
  }

  return (
    <Loading loading={loading} size='sm'>
      <div className={classNames} style={styles} onClick={handleCardClick}>
        <div className='row align-items-center g-0'>
          <div className='col-11 pe-2'>
            <div className='row align-items-center g-0'>
              <div className='col pe-1 lh-sm'>
                <MessageTitle
                  title={notification.metadata?.title}
                  important={notification.important}
                  color={settings.color}
                  textColor={settings.textColor}
                  icon={settings.icon}
                />
              </div>
              <div className={`col-auto fs-6 fw-bold text-${settings.textColor}`} title={longDate}>{shortDate}</div>
            </div>
            <div className='row'>
              <div className={`col fw-light lh-sm text-${settings.textColor}`} title={notification.metadata.body}>
                {notification.metadata.body}
              </div>
            </div>
          </div>
          <div className='col-1 text-end'>
            <MessageReadIcon status={notification.status} onClick={handleIconClick} />
          </div>
        </div>
      </div>
    </Loading>
  )
}

export {
  MessageCard
}
