import { type ReactElement } from 'react'
import Link from 'next/link'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { CustomShowcaseSettingDetailType } from '@stuller/stullercom/data-access/apollo-queries'
import { StullerDesktopLogo, ShowcaseDesktopLogoOrTitle } from './Logo'

/**
 * Stuller.com contact info/logo
 */
function StullerInfo (): ReactElement {
  return (
    <>
      <div className='d-none d-lg-flex w-100' data-gtm-click-subsection1='info'>
        <CmsContent
          contentContainerName='full-width-header-contact-information'
          noRefetch
          loadingProps={{ size: 'sm' }}
        />
      </div>
      <StullerDesktopLogo />
    </>
  )
}

/**
 * Divider between showcase info
 */
function ShowcaseInfoDivider (): ReactElement {
  return <span className='px-3 text-gray-200'>|</span>
}

/**
 * Showcase contact info/logo
 */
function ShowcaseInfo (): ReactElement | null {
  const { showcaseSettings, getCustomShowcaseSetting, isEmbeddedJewelerShowcase } = useAuth()
  const showShowcasePhone = showcaseSettings?.phoneNumber != null
  const showShowcaseContactLinks = getCustomShowcaseSetting(CustomShowcaseSettingDetailType.HideContactLinksInHeader)?.[0] !== 'true'
  const showShowcaseAboutUs = showcaseSettings?.aboutUsIsChecked === true && showcaseSettings?.aboutUs != null
  const showShowcaseContactUs = showcaseSettings?.contactUsIsChecked === true && showcaseSettings?.contactUs != null
  const linkClassNames = 'link-dark fw-light text-decoration-none'

  if (isEmbeddedJewelerShowcase) {
    return null
  }

  return (
    <>
      {showShowcaseContactLinks && (
        <div className='d-none d-lg-block fs-4 ps-4'>
          {showShowcasePhone && (
            <a href={`tel:${showcaseSettings?.phoneNumber ?? ''}`} className={linkClassNames}>{showcaseSettings?.phoneNumber}</a>
          )}
          {showShowcaseAboutUs && (
            <>
              {showShowcasePhone && <ShowcaseInfoDivider />}
              <Link href='/aboutthisjeweler' className={linkClassNames}>About Us</Link>
            </>
          )}
          {showShowcaseContactUs && (
            <>
              {(showShowcasePhone || showShowcaseAboutUs) && <ShowcaseInfoDivider />}
              <Link href='/contactthisjeweler' className={linkClassNames}>Contact Us</Link>
            </>
          )}
        </div>
      )}
      <ShowcaseDesktopLogoOrTitle />
    </>
  )
}

/**
 * The left side of the utility nav with contact info etc.
 */
function Info (): ReactElement {
  const { isShowcase } = useAuth()

  return isShowcase ? <ShowcaseInfo /> : <StullerInfo />
}

export {
  Info
}
