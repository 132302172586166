import {
  forwardRef,
  type ComponentPropsWithoutRef,
  useContext,
  useEffect,
  useRef,
  useImperativeHandle,
  type ChangeEventHandler
} from 'react'
import clsx from 'clsx'
import { type AsyncFunction } from '@stuller/shared/util/type-helpers'
import { FormGroupContext } from '../../../..'

type InputTagProps = Omit<ComponentPropsWithoutRef<'input'>, 'size' | 'onChange'>

export interface InputProps extends InputTagProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Type of the input
   */
  type?: 'text' | 'checkbox' | 'radio' | 'email' | 'password' | 'search' | 'url' | 'file' | 'number' | 'hidden' | 'tel'
  /**
   * Indicates to render the input as a pill (`.form-control` only)
   */
  pill?: boolean
  /**
   * Size of the input
   * Bootstrap size not HTML `size` (which we don't need cause it shouldn't be used)
   */
  size?: 'sm' | 'lg'
  /**
   * Indicates the input is invalid to show fail style
   */
  invalid?: boolean | string
  /**
   * Indicates the input is valid to show success style
   */
  valid?: boolean | string
  /**
   * The html size property (again, prolly shouldn't use this, prefer CSS sizing)
   */
  htmlSize?: ComponentPropsWithoutRef<'input'>['size']
  /**
   * Indicates to show as plaintext
   */
  plaintext?: boolean
  /*
   * Indicates that the input (checkbox only) is indeterminate
   */
  indeterminate?: boolean
  /**
   * On change handler with async
   */
  onChange?: AsyncFunction<ChangeEventHandler<HTMLInputElement>>
}

/**
 * Input component for rendering any `<input>` element.
 *
 * This component can be used to render regular text inputs, checkboxes, radios, emails, passwords, etc.
 * See documentation for [<input>](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input).
 *
 * See `Form` for more usage examples.
 * Remember to use `value` or `checked` for controlled components.
 */
const Input = forwardRef<HTMLInputElement, InputProps>(({
  id: idIn,
  className,
  type = 'text',
  pill = false,
  size,
  invalid = false,
  valid = false,
  htmlSize,
  plaintext = false,
  indeterminate,
  onChange,
  ...otherAttributes
}, ref) => {
  const { groupId } = useContext(FormGroupContext)
  const id = idIn ?? groupId
  const innerRef = useRef<HTMLInputElement>(null)
  // Expose innerRef, must use non-null assertion because signature requires it and conditional is not allowed
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => innerRef.current!, [])
  const classNames = clsx(
    plaintext
      ? 'form-control-plaintext'
      : ['checkbox', 'radio'].includes(type)
          ? 'form-check-input'
          : [
              'form-control',
              pill && 'rounded-pill'
            ],
    size != null && `form-control-${size}`,
    invalid !== false && invalid !== '' && 'is-invalid',
    valid === true && 'is-valid',
    className
  )

  useEffect(() => {
    if (innerRef.current != null && indeterminate != null) {
      innerRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  return (
    <input
      id={id}
      className={classNames}
      type={type}
      size={htmlSize}
      onChange={onChange}
      ref={innerRef}
      {...otherAttributes}
    />
  )
})
Input.displayName = 'Input'

export {
  Input
}
