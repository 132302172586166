// Stuller.com favicons
export const WEB_FAVICON_16 = 'https://meteor.stullercloud.com/das/102493056'
export const WEB_FAVICON_180 = 'https://meteor.stullercloud.com/das/102493050'
export const WEB_FAVICON_192 = 'https://meteor.stullercloud.com/das/102493047'
export const WEB_FAVICON_32 = 'https://meteor.stullercloud.com/das/102493057'
export const WEB_FAVICON_ICO = 'https://assets.stullercloud.com/das/102492233.ico'
export const WEB_FAVICON_MASK = 'https://meteor.stullercloud.com/das/102493063'
export const WEB_FAVICON_TILE = 'https://meteor.stullercloud.com/das/102493058'

// Showcase favicons
export const SHOWCASE_FAVICON = 'https://meteor.stullercloud.com/das/106816089'
export const SHOWCASE_FAVICON_ICO = 'https://assets.stullercloud.com/das/106816089.ico'

// Browser icons
export const CHROME_ICON = 'https://meteor.stullercloud.com/das/100963544?wid=75&$sharpenpng$'
export const EDGE_ICON = 'https://meteor.stullercloud.com/das/100963545?wid=75&$sharpenpng$'
export const FIREFOX_ICON = 'https://meteor.stullercloud.com/das/100963547?wid=75&$sharpenpng$'
export const SAFARI_ICON = 'https://meteor.stullercloud.com/das/100963546?wid=75&amp;$sharpenpng$'

// Errors images
export const ERROR_403_IMAGE = 'https://assets.stullercloud.com/web/static/i/server/important.png'
export const ERROR_404_IMAGE = 'https://assets.stullercloud.com/web/static/i/server/404.png'
export const ERROR_500_IMAGE = 'https://assets.stullercloud.com/web/static/i/server/error_2.jpg'

// METATAGS
export const METATAG_SHOWCASE_LOGO = 'https://meteor.stullercloud.com/das/62022737?wid=400&fmt=smart-alpha'
export const METATAG_TWITTER_IMAGE = 'https://meteor.stullercloud.com/das/61882824?size=1200,630&fmt=smart-alpha'
export const METATAG_OG_IMAGE = 'https://meteor.stullercloud.com/das/61882824?size=1200,630&fmt=smart-alpha'

// Stuller logos
export const STULLER_LOGO_DESKTOP = 'https://assets.stullercloud.com/web/static/i/stuller-logo-desktop.svg'
export const STULLER_LOGO_MOBILE = 'https://assets.stullercloud.com/web/static/i/stuller-s-smaller-mobile.svg'

// Showcase market rates
export const SHOWCASE_MARKET_RATES_GOLD_ICON = 'https://meteor.stullercloud.com/das/15251888?wid=30&fmt=smart-alpha'
export const SHOWCASE_MARKET_RATES_PLATINUM_ICON = 'https://meteor.stullercloud.com/das/15251889?wid=30&fmt=smart-alpha'
export const SHOWCASE_MARKET_RATES_SILVER_ICON = 'https://meteor.stullercloud.com/das/15251890?wid=30&fmt=smart-alpha'

// Membership logos
export const MEMBERSHIP_STULLER_FIRST_ICON = 'https://assets.stullercloud.com/web/static/i/full-width/stuller-first.svg'
export const MEMBERSHIP_LOCATE_A_JEWELER_ICON = 'https://assets.stullercloud.com/web/static/i/full-width/locate-a-jeweler.svg'
export const MEMBERSHIP_CAD_CAM_ICON = 'https://assets.stullercloud.com/web/static/i/full-width/cad-cam.svg'

// Image Coming Soon
export const IMAGE_COMING_SOON = 'https://meteor.stullercloud.com/image_coming_soon'
