import { type ReactElement } from 'react'
import clsx from 'clsx'
import { useTransition } from 'transition-hook'
import { Collapse, Popover } from '@stuller/stullercom/ui'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { MobileMainLinks } from './MobileMainLinks'
import { MobileFooter } from './MobileFooter'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'
import { MobileQuickLinks } from './MobileQuickLinks'

interface MobilePopoverProps {
  /**
   * Whether the popover is open
   */
  open: boolean
  /**
   * Function to set the open state
   */
  onOpenChange: (open: boolean) => void
  /**
   * Reference element for the popover
   */
  reference: HTMLDivElement | null
}

interface CloseButtonProps {
  /**
   * Function to close the popover
   */
  handleClose: () => void
}

/**
 * Close button for main nav mobile popover
 */
function MobileClose ({ handleClose }: CloseButtonProps): ReactElement {
  return (
    <div className='row g-0 align-items-center py-4 cursor-pointer' onClick={handleClose}>
      <div className='col-auto ms-4'>
        <FontAwesomeIcon icon={fas.faArrowLeft} className='fs-2' />
      </div>
      <div className='col-auto ps-3 me-4 fs-4 fw-light text-cyan-300'>
        Close
      </div>
    </div>
  )
}

/**
 * Popover for the main navigation
 */
function MobilePopover ({
  open,
  onOpenChange,
  reference
}: MobilePopoverProps): ReactElement {
  const { isShowcase } = useAuth()
  const classNames = clsx(
    'text-white border-0 rounded-0',
    isShowcase ? 'bg-gray-800' : 'bg-cyan-700'
  )
  const { shouldMount } = useTransition(open, 400)

  function handleClose (): void {
    onOpenChange(false)
  }

  return (
    <Popover
      open={shouldMount}
      onOpenChange={onOpenChange}
      reference={reference}
      dismiss
      placement='bottom-start'
      className={classNames}
      offset={({ rects }) => -rects.reference.height}
      flip={false}
      shift={false}
      style={{
        boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.4)',
        '--bs-popover-max-width': 'unset'
      }}
      data-gtm-click-section='main-nav'
    >
      <Collapse isOpen={open}>
        <div className='container-fluid g-0'>
          <MobileClose handleClose={handleClose} />
          <MobileMainLinks />
          <MobileQuickLinks />
          <MobileFooter />
        </div>
      </Collapse>

      <style jsx>{`
        div {
          min-width: calc(100vw - 15px);
        }

        @media (min-width: ${variables.breakpointSm}) {
          div {
            min-width: 350px;
          }
        }
      `}
      </style>
    </Popover>
  )
}

export {
  MobilePopover
}
