
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ConfigurationDetail": [
      "ChainConfigurationDetail",
      "EarringConfigurationDetail",
      "EngravingConfigurationDetail",
      "HeadConfigurationDetail",
      "ImprintingConfigurationDetail",
      "KitConfigurationDetail",
      "MatchingChainConfigurationDetail",
      "MonogramConfigurationDetail",
      "RingSizeConfigurationDetail",
      "SpecialFinishConfigurationDetail",
      "StoneSettingConfigurationDetail"
    ],
    "EngravingConfiguration": [
      "CustomerLogoEngravingLocationConfiguration",
      "HandwrittenEngravingLocationConfiguration",
      "PatternEngravingLocationConfiguration",
      "StandardEngravingLocationConfiguration"
    ],
    "IPrice": [
      "ComponentPrice",
      "Price"
    ],
    "ImprintingLocationConfiguration": [
      "CustomerLogoHotStampingLocationConfiguration",
      "CustomerLogoImprintingLocationConfiguration"
    ],
    "Item": [
      "Configuration",
      "Product",
      "SerializedProduct"
    ],
    "MountedStone": [
      "CustomerStone",
      "Product",
      "SerializedProduct"
    ],
    "PaymentMethod": [
      "COD",
      "CreditCard",
      "NormalTerms"
    ]
  }
};
      export default result;
    