import { type ReactElement } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { useAuth } from '@stuller/stullercom/feat/auth'
import {
  type QuickLink as QuickLinkType,
  quickLinks,
  showcaseQuickLinks
} from './links'

interface QuickLinkProps extends QuickLinkType {
}

/**
 * Quicklink item
 */
function QuickLink ({ label, url }: QuickLinkProps): ReactElement {
  const { isShowcase } = useAuth()
  const classNames = clsx(
    'lh-sm py-4 px-4 px-xl-4 fw-light link-white text-decoration-none',
    isShowcase ? 'fs-6 fs-xl-base' : 'fs-4'
  )

  return (
    <Link href={url} className={classNames} data-test={label}>
      {label}
    </Link>
  )
}

/**
 * QuickLink items
 */
function QuickLinks (): ReactElement | null {
  const auth = useAuth()
  const { isShowcase } = auth
  const links = (isShowcase ? showcaseQuickLinks : quickLinks)
    .filter(link => link.show(auth) && link.showInNavBar)

  if (links.length === 0) {
    return null
  }

  return (
    <div className='d-flex align-items-center ms-auto'>
      {links.map(link => <QuickLink key={link.label} {...link} />)}
    </div>
  )
}

export {
  QuickLinks
}
