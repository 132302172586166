/**
 * Chat settings interface
 * Sandbox refresh will/can update the following parameters
 */
export interface ChatSettings {
  id: string
  orgName: string
  chatDisabled: boolean
  baseLiveAgentUrl: string
  baseLiveAgentContentUrl: string
  salesforceUrl: string
  salesforceFlowUrl: string
  deploymentId: string
  buttonId: string
  srcAttribute: string
  externalScript: string
  priority: number
  displayHelpButton: boolean
  chatName: string
  orgId: string
  loadingText: string
  defaultMinimizedText: string
  disabledMinimizedText: string
  eswLiveAgentDevNameId: string
  eswLiveAgentDevName: string
  isOfflineSupportEnabled: boolean
  offlineSupportMinimizedText: string
  autoOpenPostChat: boolean
  storageDomain: string
  fallBackRoutingId: string
  salesforceRoutingFieldName: string
  salesforceRoutingFieldValues: string
  salesforceTranscriptFieldsJson: string
}

/**
 * Salesforce transcript fields JSON shape and expected field names
 */
const transcriptFieldsJson = [
  {
    label: 'First Name',
    transcriptFields: ['First_Name__c']
  },
  {
    label: 'Last Name',
    transcriptFields: ['Last_Name__c']
  },
  {
    label: 'Email',
    transcriptFields: ['Email__c']
  },
  {
    label: 'How can we help you today?',
    transcriptFields: ['Customer_Chat_Reason__c']
  },
  {
    label: 'Tell us a little more',
    transcriptFields: ['Description__c']
  },
  {
    label: 'Order Number',
    transcriptFields: ['Order_Number__c']
  }
]

/**
 * Chat Settings array contains objects for both Production & Partial
 * User should filter based on OrgName = SALESFORCE_ENVIRONMENT
 */
const chatSettings: ChatSettings[] = [
  {
    id: '1',
    orgName: 'production',
    chatDisabled: false,
    baseLiveAgentUrl: 'https://d.la4-c4-ph2.salesforceliveagent.com/chat',
    baseLiveAgentContentUrl: 'https://c.la4-c4-ph2.salesforceliveagent.com/content',
    salesforceUrl: 'https://stuller.my.salesforce.com',
    salesforceFlowUrl: 'https://stuller.secure.force.com/liveAgentSetupFlow',
    deploymentId: '5721R000000TqDs',
    buttonId: '5733p000000k9cu',
    srcAttribute: 'https://stuller.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    externalScript: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    priority: 0,
    displayHelpButton: false,
    chatName: 'All',
    orgId: '00D36000000bnxd',
    loadingText: 'Loading...',
    defaultMinimizedText: 'Live Chat',
    disabledMinimizedText: 'Agent Offline',
    eswLiveAgentDevNameId: 'Embedded_Chat_Group_All',
    eswLiveAgentDevName: 'Embedded_Chat_Group_All',
    isOfflineSupportEnabled: true,
    offlineSupportMinimizedText: 'Live Chat',
    autoOpenPostChat: true,
    storageDomain: 'stuller.com',
    fallBackRoutingId: '5733p000000k9cp,5733p000000k9ck',
    salesforceRoutingFieldName: 'Chat_Reason__c',
    salesforceRoutingFieldValues: '',
    salesforceTranscriptFieldsJson: JSON.stringify(transcriptFieldsJson)
  },
  {
    id: '2',
    orgName: 'production',
    chatDisabled: false,
    baseLiveAgentUrl: 'https://d.la4-c4-ph2.salesforceliveagent.com/chat',
    baseLiveAgentContentUrl: 'https://c.la4-c4-ph2.salesforceliveagent.com/content',
    salesforceUrl: 'https://stuller.my.salesforce.com',
    salesforceFlowUrl: 'https://stuller.secure.force.com/liveAgentSetupFlow',
    deploymentId: '5721R000000TqDs',
    buttonId: '5733p000000k9cp',
    srcAttribute: 'https://stuller.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    externalScript: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    priority: 0,
    displayHelpButton: false,
    chatName: 'Sales',
    orgId: '00D36000000bnxd',
    loadingText: 'Loading...',
    defaultMinimizedText: 'Live Chat',
    disabledMinimizedText: 'Agent Offline',
    eswLiveAgentDevNameId: 'Embedded_Chat_Group_All',
    eswLiveAgentDevName: 'Embedded_Chat_Group_All',
    isOfflineSupportEnabled: true,
    offlineSupportMinimizedText: 'Live Chat',
    autoOpenPostChat: true,
    storageDomain: 'stuller.com',
    fallBackRoutingId: '5733p000000k9cp,5733p000000k9ck',
    salesforceRoutingFieldName: 'Chat_Reason__c',
    salesforceRoutingFieldValues: 'I have a general question,I would like to place an order,I have a Customer Service inquiry,Other',
    salesforceTranscriptFieldsJson: JSON.stringify(transcriptFieldsJson)
  },
  {
    id: '3',
    orgName: 'production',
    chatDisabled: false,
    baseLiveAgentUrl: 'https://d.la4-c4-ph2.salesforceliveagent.com/chat',
    baseLiveAgentContentUrl: 'https://c.la4-c4-ph2.salesforceliveagent.com/content',
    salesforceUrl: 'https://stuller.my.salesforce.com',
    salesforceFlowUrl: 'https://stuller.secure.force.com/liveAgentSetupFlow',
    deploymentId: '5721R000000TqDs',
    buttonId: '5733p000000k9ck',
    srcAttribute: 'https://stuller.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    externalScript: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    priority: 0,
    displayHelpButton: false,
    chatName: 'CadCam',
    orgId: '00D36000000bnxd',
    loadingText: 'Loading...',
    defaultMinimizedText: 'Live Chat',
    disabledMinimizedText: 'Agent Offline',
    eswLiveAgentDevNameId: 'Embedded_Chat_Group_All',
    eswLiveAgentDevName: 'Embedded_Chat_Group_All',
    isOfflineSupportEnabled: true,
    offlineSupportMinimizedText: 'Live Chat',
    autoOpenPostChat: true,
    storageDomain: 'stuller.com',
    fallBackRoutingId: '5733p000000k9ck,5733p000000k9cp',
    salesforceRoutingFieldName: 'Chat_Reason__c',
    salesforceRoutingFieldValues: 'I have a CADCAM related question',
    salesforceTranscriptFieldsJson: JSON.stringify(transcriptFieldsJson)
  },
  {
    id: '4',
    orgName: 'production',
    chatDisabled: false,
    baseLiveAgentUrl: 'https://d.la4-c4-ph2.salesforceliveagent.com/chat',
    baseLiveAgentContentUrl: 'https://c.la4-c4-ph2.salesforceliveagent.com/content',
    salesforceUrl: 'https://stuller.my.salesforce.com',
    salesforceFlowUrl: 'https://stuller.secure.force.com/liveAgentSetupFlow',
    deploymentId: '5721R000000TqDs',
    buttonId: '5733p000000Go65',
    srcAttribute: 'https://stuller.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    externalScript: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    priority: 0,
    displayHelpButton: false,
    chatName: 'DiamondAndGemstones',
    orgId: '00D36000000bnxd',
    loadingText: 'Loading...',
    defaultMinimizedText: 'Live Chat',
    disabledMinimizedText: 'Agent Offline',
    eswLiveAgentDevNameId: 'Embedded_Chat_Group_All',
    eswLiveAgentDevName: 'Embedded_Chat_Group_All',
    isOfflineSupportEnabled: true,
    offlineSupportMinimizedText: 'Live Chat',
    autoOpenPostChat: true,
    storageDomain: 'stuller.com',
    fallBackRoutingId: '5733p000000Go65,5733p000000k9cp',
    salesforceRoutingFieldName: 'Chat_Reason__c',
    salesforceRoutingFieldValues: 'I have a Diamonds and Gemstones question',
    salesforceTranscriptFieldsJson: JSON.stringify(transcriptFieldsJson)
  },
  {
    id: '5',
    orgName: 'production',
    chatDisabled: false,
    baseLiveAgentUrl: 'https://d.la4-c4-ph2.salesforceliveagent.com/chat',
    baseLiveAgentContentUrl: 'https://c.la4-c4-ph2.salesforceliveagent.com/content',
    salesforceUrl: 'https://stuller.my.salesforce.com',
    salesforceFlowUrl: 'https://stuller.secure.force.com/liveAgentSetupFlow',
    deploymentId: '5721R000000TqDs',
    buttonId: '5733p000000k9cz',
    srcAttribute: 'https://stuller.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    externalScript: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    priority: 0,
    displayHelpButton: false,
    chatName: 'Tools',
    orgId: '00D36000000bnxd',
    loadingText: 'Loading...',
    defaultMinimizedText: 'Live Chat',
    disabledMinimizedText: 'Agent Offline',
    eswLiveAgentDevNameId: 'Embedded_Chat_Group_All',
    eswLiveAgentDevName: 'Embedded_Chat_Group_All',
    isOfflineSupportEnabled: true,
    offlineSupportMinimizedText: 'Live Chat',
    autoOpenPostChat: true,
    storageDomain: 'stuller.com',
    fallBackRoutingId: '5733p000000k9ck,5733p000000k9cp',
    salesforceRoutingFieldName: 'Chat_Reason__c',
    salesforceRoutingFieldValues: 'I have a Tools question',
    salesforceTranscriptFieldsJson: JSON.stringify(transcriptFieldsJson)
  },
  {
    id: '6',
    orgName: 'partial',
    chatDisabled: false,
    baseLiveAgentContentUrl: 'https://c.la3-c1cs-ia2.salesforceliveagent.com/content',
    baseLiveAgentUrl: 'https://d.la3-c1cs-ia2.salesforceliveagent.com/chat',
    salesforceUrl: 'https://stuller--partial.sandbox.my.salesforce.com',
    salesforceFlowUrl: 'https://stuller--partial.sandbox.my.salesforce-sites.com/liveAgentSetupFlow',
    deploymentId: '5721R000000TqDs',
    buttonId: '5733p000000k9cu',
    srcAttribute: 'https://stuller--partial.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    externalScript: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    priority: 0,
    displayHelpButton: false,
    chatName: 'All',
    orgId: '00D2i0000001Bc2',
    loadingText: 'Loading...',
    defaultMinimizedText: 'DEV Live Chat',
    disabledMinimizedText: 'Agent Offline',
    eswLiveAgentDevNameId: 'Embedded_Chat_Group_All',
    eswLiveAgentDevName: 'Embedded_Chat_Group_All',
    isOfflineSupportEnabled: true,
    offlineSupportMinimizedText: 'DEV - Live Chat',
    autoOpenPostChat: true,
    storageDomain: 'dev.stullercloud.com',
    fallBackRoutingId: '5733p000000k9cp,5733p000000k9ck',
    salesforceRoutingFieldName: 'Chat_Reason__c',
    salesforceRoutingFieldValues: '',
    salesforceTranscriptFieldsJson: JSON.stringify(transcriptFieldsJson)
  }
]

/**
 * Return LiveChat settings based on Salesforce environment
 */
function getChatSettings (salesforceEnvironment: string): ChatSettings[] {
  if (salesforceEnvironment == null) {
    return []
  }

  return chatSettings.filter(s => s.orgName === salesforceEnvironment.toLowerCase())
}

export {
  getChatSettings
}
