import { type ReactElement, useState } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { FloatingDelayGroup } from '@floating-ui/react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useGetMainNavCategoriesQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { Spinner } from '@stuller/stullercom/ui'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { mainLinks, type MainLink as MainLinkType } from './links'
import { MainLinksPopover } from './MainLinksPopover'

interface MainLinkProps extends MainLinkType {
}

/**
 * A single main nav link with popover
 */
function MainLink ({
  label,
  url,
  contentContainerId,
  contentContainerName,
  sublinks
}: MainLinkProps): ReactElement {
  const { isShowcase } = useAuth()
  const showPopover = contentContainerId != null || contentContainerName != null || sublinks != null
  const [open, handleOpenChange] = useState(false)
  const [reference, setReference] = useState<HTMLAnchorElement | null>(null)
  const classNames = clsx(
    'd-flex lh-sm py-4 px-4 px-xl-4 text-white text-decoration-none ',
    isShowcase ? 'fw-light fs-base fs-xl-4' : 'fw-normal fs-3'
  )

  return (
    <>
      <Link href={url} className={classNames} ref={setReference} data-gtm-click-subsection1={label}>
        <div className='align-self-center'>
          {label}
        </div>
      </Link>
      {showPopover && (
        <MainLinksPopover
          open={open}
          onOpenChange={handleOpenChange}
          reference={reference}
          label={label}
          url={url}
          contentContainerId={contentContainerId}
          contentContainerName={contentContainerName}
          sublinks={sublinks}
        />
      )}
    </>
  )
}

/**
 * Main nav link items
 */
function MainLinks (): ReactElement {
  const { isShowcase } = useAuth()
  const { data, loading } = useGetMainNavCategoriesQuery()

  if (isShowcase && loading) {
    return (
      <div className='d-flex ms-3'>
        <Spinner size='sm' color='white' />
      </div>
    )
  }

  return (
    <div className='d-flex align-items-stretch'>
      <FloatingDelayGroup delay={{ open: 250, close: 10 }}>
        {isShowcase
          ? data?.mainNavCategories.map(category => (
            <MainLink
              key={category.id}
              label={category.mainNavDisplayName}
              url={category.url}
              contentContainerId={category.mainNavContentContainerId}
            />
          ))
          : mainLinks.map(link => (
            <MainLink
              key={link.label}
              label={link.label}
              url={link.url}
              contentContainerName={link.contentContainerName}
              sublinks={link.sublinks}
            />))}
      </FloatingDelayGroup>
      <div className='d-none d-xl-flex'>
        <div className='align-self-center'>
          <CmsContent contentContainerName='main-nav-callout-button' skipLoading />
        </div>
      </div>
    </div>
  )
}

export {
  MainLinks
}
