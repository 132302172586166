import { type ReactElement, useMemo } from 'react'
import { type ShippingCountdown as ShippingCountdownObject } from '@stuller/stullercom/data-access/apollo-queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useDurationCountdown } from '@stuller/shared/util/react-hooks'
import { CountdownInfoSectionPreviousOrder } from './CountdownInfoSectionPreviousOrder'
import { CountdownInfoSectionNoOrder } from './CountdownInfoSectionNoOrder'
import dayjs from 'dayjs'
import type plugin from 'dayjs/plugin/utc'

interface ShippingCountdownProps {
  countdown: ShippingCountdownObject
}

interface ShippingCountdownInnerProps {
  countdown: ShippingCountdownObject
  duration: plugin.Duration
}

/**
 * A component to show the shipping countdown
 */
function ShippingCountdown ({ countdown }: ShippingCountdownProps): ReactElement | null {
  const cutoffDate = useMemo(() => dayjs(countdown.cutoffDate), [countdown])
  const duration = useDurationCountdown(cutoffDate, 1000)

  if (countdown.countdownKind === 'SCHEDULED_ORDER' || duration.as('milliseconds') <= 0) {
    return null
  }

  return (<ShippingCountdownInner countdown={countdown} duration={duration} />)
}

function ShippingCountdownInner ({ countdown, duration }: ShippingCountdownInnerProps): ReactElement {
  return (
    <div className='mb-4'>
      <div className='row'>
        <div className='col-1 me-4 d-flex align-items-center'>
          <FontAwesomeIcon className='fs-2 text-primary' icon={fas.faTruckFast} />
        </div>
        <div className='col'>
          {countdown.countdownKind === 'NO_ORDER' && <CountdownInfoSectionNoOrder duration={duration} />}
          {countdown.countdownKind === 'PREVIOUS_ORDER' && <CountdownInfoSectionPreviousOrder duration={duration} />}
        </div>
      </div>
    </div>
  )
}

export {
  ShippingCountdown
}
