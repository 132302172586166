import { type NextPageContext } from 'next'

/**
 * Gets the user agent string from request or client navigator
 */
function getUA (ctx: NextPageContext): string {
  return ctx.req?.headers['user-agent'] ?? navigator.userAgent
}

export {
  getUA
}
