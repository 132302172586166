import { type ReactElement, useEffect, useMemo, useState } from 'react'
import {
  useGetNotificationsFeedLazyQuery,
  useGetNotificationsUnreadCountQuery,
  type NotificationType,
  NotificationStatus,
  useUpdateNotificationsFeedReadMutation
} from '@stuller/stullercom/data-access/apollo-queries'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { MessagesPopoverContext } from './MessagesPopoverContext'
import { MenuPopover, type MenuPopoverProps } from '../MenuPopover'
import { MessagesPopoverInner } from './MessagesPopoverInner'
import {
  NOTIFICATION_APP,
  MESSAGES_UPDATES_TYPES,
  SHIPMENT_TYPES
} from './constants'

interface MessagesPopoverProps extends MenuPopoverProps {
}

/**
 * The popover for messages (notifications, filters, etc.)
 */
function MessagesPopover ({
  open,
  onOpenChange,
  reference
}: MessagesPopoverProps): ReactElement {
  const [unreadImportantOnly, setUnreadImportantOnly] = useState(false)
  const [shipments, setShipments] = useState(false)
  const [messagesUpdates, setMessagesUpdates] = useState(true)
  const [countChanged, setCountChanged] = useState(false)
  const unreadCount = useGetNotificationsUnreadCountQuery({
    variables: { app: NOTIFICATION_APP },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })
  const types = useMemo<NotificationType[] | undefined>(() => {
    if (!unreadImportantOnly) {
      return [
        ...(shipments ? SHIPMENT_TYPES : []),
        ...(messagesUpdates ? MESSAGES_UPDATES_TYPES : [])
      ]
    }
  }, [shipments, messagesUpdates, unreadImportantOnly])
  const unreadImportantFilter = useMemo(() => {
    if ((unreadCount.data?.viewer?.notificationsUnreadCount.important ?? 0) > 0) {
      return {
        important: unreadImportantOnly ? true : undefined,
        status: unreadImportantOnly ? NotificationStatus.Unread : undefined
      }
    }
  }, [unreadImportantOnly, unreadCount.data?.viewer?.notificationsUnreadCount.important])
  const [, feed] = useGetNotificationsFeedLazyQuery({
    variables: {
      app: NOTIFICATION_APP,
      limit: 40,
      types,
      ...unreadImportantFilter
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })
  const [updateFeedRead, feedReadUpdate] = useUpdateNotificationsFeedReadMutation({
    variables: { input: { app: NOTIFICATION_APP } },
    onError: () => {
      console.error('Could not update notification feed')
    },
    onCompleted: () => {
      void unreadCount.refetch()
      void feed.refetch()
    }
  })

  // Trigger fetching data on start, if count changes, or important count changes
  useEffect(() => {
    if (unreadCount.data == null) {
      return
    }

    // If the unread count changed set a flag to refetch the feed on reopen
    if (unreadCount.data?.viewer?.notificationsUnreadCount.total !== unreadCount.previousData?.viewer?.notificationsUnreadCount.total) {
      setCountChanged(true)
    }

    // Reset the unread important only flag if there are no unread important notifications
    if (unreadCount.data?.viewer?.notificationsUnreadCount.important === 0) {
      setUnreadImportantOnly(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadCount.data])

  // Refetch on open if a query errored or the unread count changed
  useEffect(() => {
    if (open === true) {
      setCountChanged(false)
      if (unreadCount.error != null) {
        void unreadCount.refetch()
      } else if (feed.error != null || countChanged) {
        void feed.refetch()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <MenuPopover
      open={open}
      onOpenChange={onOpenChange}
      reference={reference}
    >
      <div data-gtm-click-subsection1='Messages'>
        <MessagesPopoverContext.Provider
          value={{
            feed,
            unreadCount,
            updateFeedRead,
            feedReadUpdate,
            unreadImportantOnly,
            setUnreadImportantOnly,
            shipments,
            setShipments,
            messagesUpdates,
            setMessagesUpdates
          }}
        >
          <CmsContent contentContainerName='messages-announcement' skipLoading />
          <MessagesPopoverInner />
        </MessagesPopoverContext.Provider>
      </div>
    </MenuPopover>
  )
}

export {
  MessagesPopover
}
