import { type ReactElement, useState } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { Spinner } from '@stuller/stullercom/ui'
import {
  useFloating,
  useInteractions,
  useHover,
  safePolygon,
  FloatingPortal
} from '@floating-ui/react'
import { useTransition } from 'transition-hook'
import { type MainLink } from './links'
import { MainLinksContent } from './MainLinksContent'

interface PopoverSublinksProps {
  /**
   * The sublinks from `MainLink` results
   */
  sublinksResult: ReturnType<NonNullable<MainLink['sublinks']>>
}

interface PopoverSublinksInnerProps {
  /**
   * The sublinks from `MainLink`
   */
  links: MainLink[]
  /**
   * The featured sublinks from `MainLink`
   */
  featured: MainLink[]
}

interface SublinkProps extends MainLink {
  /**
   * The index of the sublink in the list
   */
  index: number
  /**
   * Indicates the link is featured
   */
  featured?: boolean
}

/**
 * A single sublink that renders it's main nav popover content via the floating UI portal
 */
function Sublink ({
  index,
  label,
  url,
  contentContainerId,
  featured = false
}: SublinkProps): ReactElement {
  const [open, handleOpenChange] = useState<boolean>(index === 0)
  const { refs, context } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    placement: 'right'
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      handleClose: safePolygon()
    })
  ])
  const classNames = clsx(
    'd-block text-white fs-4 fw-light text-decoration-none px-6 py-2 lh-sm',
    open && 'bg-cyan-700',
    featured && 'text-blue-200'
  )
  const { stage } = useTransition(open, 200)

  return (
    <>
      <Link
        href={url}
        className={classNames}
        ref={refs.setReference}
        {...getReferenceProps()}
        data-gtm-click-subsection2={label}
      >
        {label}
      </Link>
      {open && (
        <FloatingPortal id='main-nav-popover-sublinks-inner-portal'>
          <div
            ref={refs.setFloating}
            className='row w-100 h-100 g-0 transition-opacity'
            {...getFloatingProps()}
            style={{ opacity: stage === 'enter' ? 1 : 0 }}
            data-gtm-click-subsection2={label}
          >
            <MainLinksContent
              label={label}
              url={url}
              contentContainerId={contentContainerId}
            />
          </div>
        </FloatingPortal>)}
    </>
  )
}

/**
 * Inner main nav popover sublinks once loading is complete
 */
function PopoverSublinksInner ({ links, featured }: PopoverSublinksInnerProps): ReactElement {
  return (
    <>
      <div
        className='col-2 bg-cyan-500 ms-3 px-0'
        style={{ boxShadow: 'rgba(0, 0, 0, .15) 0px 30px 15px -15px inset' }}
      >
        <div className='py-6'>
          {links.map((l, i) => (
            <Sublink
              key={l.label}
              index={i}
              label={l.label}
              url={l.url}
              contentContainerId={l.contentContainerId}
            />))}
          {featured.length > 0 && (
            <>
              <hr className='mx-6 text-blue-200' />
              {featured.map((l, i) => (
                <Sublink
                  key={l.label}
                  index={links.length + i}
                  label={l.label}
                  url={l.url}
                  contentContainerId={l.contentContainerId}
                  featured
                />))}
            </>)}
        </div>
      </div>
      <div
        className='col p-0'
        id='main-nav-popover-sublinks-inner-portal'
      >
        <style jsx>{`
          div > :global([data-floating-ui-portal]) {
            width: 100%;
            height: 100%;
          }
        `}
        </style>
      </div>
    </>
  )
}

/**
 * Sublinks for the main navigation popover and shows the content for the selected link
 */
function PopoverSublinks ({ sublinksResult }: PopoverSublinksProps): ReactElement {
  const { loading, links, featured } = sublinksResult

  if (loading) {
    return (
      <div className='col pt-6 text-center'>
        <Spinner color='white' />
      </div>
    )
  }

  return <PopoverSublinksInner links={links} featured={featured} />
}

export {
  PopoverSublinks
}
