import {
  type ReactElement,
  memo,
  useState,
  useCallback,
  useRef,
  useEffect
} from 'react'
import Script from 'next/script'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { type LiveChatProps, useLayout } from '@stuller/stullercom/feat/layout-context'
import { useToggle } from '@stuller/shared/util/react-hooks'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge
} from '@stuller/stullercom/ui'
import { useStullerEventListener } from '@stuller/stullercom/feat/stuller-events'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { trackUniversalEvent } from '@stuller/stullercom/feat/google-tag-manager'
import { useUnsupported } from '@stuller/stullercom/feat/unsupported-banner'
import { useGetCmsLookupMinimumSupportedBrowsersQuery } from '@stuller/stullercom/data-access/apollo-queries'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'
import { getChatSettings, type ChatSettings } from './getChatSettings'
import { siteConfig } from '@stuller/stullercom/util/site-config'

/**
 * Interface to allow window.embedded_svc Salesforce embedded (for this file only)
 */
declare global {
  interface Window {
    /**
     * Salesforce embedded chat
     */
    embedded_svc?: any
  }
}

// Salesforce global environment/settings/observers/etc.
const salesforceEnvironment = siteConfig.SALESFORCE_ENVIRONMENT ?? 'partial'
const chatSettings: ChatSettings[] = getChatSettings(salesforceEnvironment)
const defaultChatSettings = chatSettings[0]
let embeddedChatObserverSet = false
let embeddedChatObserver: MutationObserver | null = null

/**
 * Activate embedded chat observer
 */
function activateEmbeddedChatObserver (): void {
  if (embeddedChatObserverSet) {
    return
  }

  const embeddedChatServiceSidebar = document.querySelector('.embeddedServiceSidebar')
  if (embeddedChatServiceSidebar != null) {
    embeddedChatObserverSet = true
    embeddedChatObserver?.observe(embeddedChatServiceSidebar, {
      childList: true,
      subtree: true,
      attributes: true
    })
  }
}

/**
 * Disconnect embedded chat observer
 */
function disconnectEmbeddedChatObserver (): void {
  embeddedChatObserverSet = false
  embeddedChatObserver?.disconnect()
}

/**
 * Prepopulate input and dispatch change event
 */
function prepopulateInput (sfdcQuerySelector: string, sfdcValue: string): void {
  const sfdcInput: HTMLInputElement | null = document.querySelector(sfdcQuerySelector)

  if (sfdcInput != null && sfdcValue != null) {
    sfdcInput.value = sfdcValue
    sfdcInput.dispatchEvent(new Event('change', { bubbles: true }))
  }
}

/**
 * Populate offline support form
 */
function prepopulateForm (prechatData: PrechatData): void {
  const { firstName, lastName, email, accountNumber } = prechatData

  if (document.getElementById('Chat_Description__c') != null) {
    // Populate the offline or online form accordingly
    if (document.getElementById('Chat_Email__c') != null) {
      prepopulateInput('input#Chat_Name__c', `${firstName} ${lastName}`.trim())
      prepopulateInput('input#Chat_Email__c', email)
      prepopulateInput('input#Account_Number__c', accountNumber)
    } else {
      prepopulateInput('input#FirstName', firstName)
      prepopulateInput('input#LastName', lastName)
      prepopulateInput('input#Email', email)
    }
  }
}

/**
 * Update ESW setttings based on values stored in chatSettings config file, set event handlers & call init()
 */
function initEsw (gslbBaseURL: string): void {
  const { embedded_svc: embeddedSvc, embedded_svc: { settings } } = window

  settings.autoOpenPostChat = defaultChatSettings.autoOpenPostChat
  settings.displayHelpButton = defaultChatSettings.displayHelpButton
  settings.language = 'en-US'
  settings.defaultMinimizedText = defaultChatSettings.defaultMinimizedText
  settings.disabledMinimizedText = defaultChatSettings.disabledMinimizedText
  settings.loadingText = defaultChatSettings.loadingText
  settings.storageDomain = (salesforceEnvironment === 'partial' && typeof window !== 'undefined') ? window.location.hostname : defaultChatSettings.storageDomain
  // Determines the "button id" or the "agents that can respond"
  settings.directToButtonRouting = (prechatFormData: any) => {
    let defaultRoutingButtonId = defaultChatSettings.buttonId
    const routingFieldName = defaultChatSettings.salesforceRoutingFieldName
    if (routingFieldName == null) {
      return defaultRoutingButtonId
    }
    for (const data of prechatFormData) {
      if (data.name === routingFieldName) {
        for (const setting of chatSettings) {
          if (setting.salesforceRoutingFieldValues != null) {
            const chatName = setting.chatName
            const buttonId = setting.buttonId
            const fallBackRouting = setting.fallBackRoutingId
            const allowedRoutingValues = setting.salesforceRoutingFieldValues.split(',')
            for (const route of allowedRoutingValues) {
              if (data.value === route) {
                if (fallBackRouting != null) {
                  // An array of button IDs, user IDs, or userId_buttonId
                  settings.fallbackRouting = fallBackRouting.split(',')
                }

                return buttonId
              }
            }
            if (chatName === 'Sales') {
              defaultRoutingButtonId = buttonId
            }
          }
        }
      }
    }

    // Dynamically changes the button ID based on what the visitor enters in the pre-chat form
    return defaultRoutingButtonId
  }

  if (settings.fallbackRouting == null && defaultChatSettings.fallBackRoutingId != null) {
    // An array of button IDs, user IDs, or userId_buttonId
    settings.fallbackRouting = defaultChatSettings.fallBackRoutingId.split(',')
  }
  settings.offlineSupportMinimizedText = defaultChatSettings.offlineSupportMinimizedText
  settings.extraPrechatFormDetails = [
    {
      label: 'Account Number',
      transcriptFields: ['Account_Number__c'],
      value: '',
      displayToAgent: true
    },
    {
      label: 'caseOrigin',
      value: 'Web Chat',
      displayToAgent: false
    },
    {
      label: 'caseStatus',
      value: 'Closed',
      displayToAgent: false
    },
    {
      label: 'caseFinalResolution',
      value: 'Automatically Closed',
      displayToAgent: false
    }
  ]

  if (defaultChatSettings.salesforceTranscriptFieldsJson != null) {
    try {
      const transcriptFields = JSON.parse(defaultChatSettings.salesforceTranscriptFieldsJson)
      for (const field in transcriptFields) {
        settings.extraPrechatFormDetails.push(field)
      }
    } catch (err: any) {
      // Do not break chat if bad json is parsed
      console.error(err.message)
    }
  }

  settings.extraPrechatInfo = [
    {
      entityName: 'Account',
      linkToEntityName: 'Case',
      linkToEntityField: 'AccountId',
      entityFieldMaps: [
        {
          isExactMatch: true,
          fieldName: 'Oracle_Account_Number__c',
          doCreate: false,
          doFind: true,
          label: 'Account Number'
        }
      ],
      saveToTranscript: 'AccountId'
    },
    {
      entityName: 'Case',
      showOnCreate: true,
      saveToTranscript: 'CaseId',
      entityFieldMaps: [{
        isExactMatch: false,
        fieldName: 'Origin',
        doCreate: true,
        doFind: false,
        label: 'caseOrigin'
      }, {
        isExactMatch: false,
        fieldName: 'Status',
        doCreate: true,
        doFind: false,
        label: 'caseStatus'
      }, {
        isExactMatch: false,
        fieldName: 'Final_Resolution__c',
        doCreate: true,
        doFind: false,
        label: 'caseFinalResolution'
      }]
    }
  ]
  settings.enabledFeatures = ['LiveAgent']
  settings.entryFeature = 'LiveAgent'

  // Chat events we could leverage from Salesforce
  // https://developer.salesforce.com/docs/atlas.en-us.228.0.snapins_web_dev.meta/snapins_web_dev/snapins_web_chat_events.htm
  embeddedSvc.init(
    defaultChatSettings.salesforceUrl,
    defaultChatSettings.salesforceFlowUrl,
    gslbBaseURL,
    defaultChatSettings.orgId,
    defaultChatSettings.eswLiveAgentDevName,
    {
      baseLiveAgentContentURL: defaultChatSettings.baseLiveAgentContentUrl,
      deploymentId: defaultChatSettings.deploymentId,
      buttonId: defaultChatSettings.buttonId,
      baseLiveAgentURL: defaultChatSettings.baseLiveAgentUrl,
      eswLiveAgentDevName: defaultChatSettings.eswLiveAgentDevNameId,
      isOfflineSupportEnabled: defaultChatSettings.isOfflineSupportEnabled
    }
  )
}

/**
 * Data needed to prepopulate the live chat form
 */
interface PrechatData {
  /**
   * First name of the logged in user from contact
   */
  firstName: string
  /**
   * Last name of the logged in user from contact
   */
  lastName: string
  /**
   * Email of the logged in user from contact
   */
  email: string
  /**
   * Account number from user default ship to
   */
  accountNumber: string
}

const LiveChat = memo(({
  disabled = false
}: LiveChatProps): ReactElement | null => {
  const { authUser, isShowcase, isSterling } = useAuth()
  const { layoutState: { marketRatesFooterShown } } = useLayout()
  const chatDisabled = disabled || authUser == null || isShowcase || defaultChatSettings.chatDisabled || isSterling
  const { data } = useGetCmsLookupMinimumSupportedBrowsersQuery()
  const browser = useUnsupported(data?.cmsLookupsByType ?? [])
  const unsupportedBrowser = browser != null
  const [buttonText, setButtonText] = useState(defaultChatSettings.defaultMinimizedText)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [buttonHidden, setButtonHidden] = useState(false)
  const [modalOpen, toggleModalOpen] = useToggle(false)
  const [minimized, setMinimized] = useState(false)
  const [unreadCount, setUnreadCount] = useState<string | null>(null)
  const handleUpdateUnreadCount = useRef<() => void>()
  const containerClass = clsx(
    minimized ? 'position-fixed z-fixed end-0' : 'position-sticky z-1 h-0',
    'pe-6 bottom-0 d-print-none'
  )

  /**
   * Add event handlers to window.embedded_svc
   */
  function addEventHandlers (): void {
    const { embedded_svc: embeddedSvc } = window

    // Add event handlers
    embeddedSvc.addEventHandler('onChatEndedByChasitor', disconnectEmbeddedChatObserver)
    embeddedSvc.addEventHandler('onChatEndedByAgent', disconnectEmbeddedChatObserver)
    embeddedSvc.addEventHandler('onIdleTimeoutOccurred', disconnectEmbeddedChatObserver)
    embeddedSvc.addEventHandler('onChatEstablished', activateEmbeddedChatObserver)

    // Chat help button clicked to start new
    embeddedSvc.addEventHandler('onHelpButtonClick', () => {
      const prechatData: PrechatData = {
        firstName: authUser?.firstName ?? '',
        lastName: authUser?.lastName ?? '',
        email: authUser?.email ?? '',
        accountNumber: authUser?.user?.defaultActiveShipToAccount?.id ?? ''
      }
      embeddedSvc.settings.prepopulatedPrechatFields = prechatData
      embeddedSvc.settings.prepopulatedPrechatFieldsProcessed = false
      embeddedSvc.settings.extraPrechatFormDetails[0].value = prechatData.accountNumber

      trackUniversalEvent({
        category: 'Live Chat',
        action: 'Button',
        label: 'Click'
      })
    })

    // Chat window opened for new or minimized
    embeddedSvc.addEventHandler('afterMaximize', () => {
      if (embeddedSvc.settings.prepopulatedPrechatFieldsProcessed === false) {
        embeddedSvc.settings.prepopulatedPrechatFieldsProcessed = true
        prepopulateForm(embeddedSvc.settings.prepopulatedPrechatFields as PrechatData)
      }
      activateEmbeddedChatObserver()
      setButtonText(defaultChatSettings.defaultMinimizedText)
      setButtonHidden(true)
      setButtonDisabled(false)
    })

    // Chat window closed
    embeddedSvc.addEventHandler('afterDestroy', () => {
      disconnectEmbeddedChatObserver()
      setButtonHidden(false)
      setButtonDisabled(false)
      setMinimized(false)
    })

    // Chat window minimized
    embeddedSvc.addEventHandler('afterMinimize', () => {
      activateEmbeddedChatObserver()
      setButtonText(defaultChatSettings.defaultMinimizedText)
      setButtonHidden(false)
      setButtonDisabled(false)
      setMinimized(true)
    })
  }

  /**
   * Handle Live Chat Button click to hijack click event of hidden Salesforce button
   */
  const handleLiveChatClick = useCallback(() => {
    if (chatDisabled) {
      toggleModalOpen()

      return
    }

    const chatButton = document.querySelector<HTMLButtonElement>('.helpButtonEnabled')
    const minimizedChatButton = document.querySelector<HTMLButtonElement>('.sidebarHeader.minimizedContainer.helpButton.embeddedServiceSidebarMinimizedDefaultUI')
    const newMessageMinimizedButton = document.querySelector<HTMLButtonElement>('.sidebarHeader.minimizedContainer.newMessage.embeddedServiceSidebarMinimizedDefaultUI')
    const postChatMinimizedButton = document.querySelector<HTMLButtonElement>('.sidebarHeader.minimizedContainer.embeddedServiceSidebarMinimizedDefaultUI')
    let newButtonText = defaultChatSettings.defaultMinimizedText

    // Based on button element present on screen, call it's click event
    if (minimizedChatButton != null) {
      minimizedChatButton.click()
    } else if (newMessageMinimizedButton != null) {
      newMessageMinimizedButton.click()
    } else if (postChatMinimizedButton != null) {
      postChatMinimizedButton.click()
    } else if (chatButton != null) {
      chatButton.click()
      newButtonText = defaultChatSettings.loadingText
      setButtonDisabled(true)
    } else {
      toggleModalOpen()
    }
    setButtonText(newButtonText)
  }, [toggleModalOpen, chatDisabled])

  /**
   * Handle updating unread message count
   */
  useEffect(() => {
    handleUpdateUnreadCount.current = () => {
      const message = document.querySelector('.embeddedServiceSidebar .minimizedText .message')

      if (message != null) {
        const text = message.textContent ?? ''
        const match = text.match(/^(\d*) Message/)

        if (match?.[1] != null) {
          if (unreadCount !== match[1]) {
            setUnreadCount(match[1])
          }
        } else if (unreadCount != null) {
          setUnreadCount(null)
        }
      }
    }
  }, [unreadCount])

  /**
   * Set embedded chat observer
   */
  function setEmbeddedChatObserver (): void {
    if (typeof MutationObserver !== 'undefined') {
      embeddedChatObserver = new MutationObserver(() => {
        // Update unread message count
        handleUpdateUnreadCount.current?.()
      })
    }
  }

  /**
   * Handle script loaded
   */
  function handleChatReady (): void {
    setEmbeddedChatObserver()
    addEventHandlers()
    initEsw('https://service.force.com')

    if (modalOpen) {
      toggleModalOpen()
    }
  }

  /**
   * Handle something went wrong on script load
   */
  function handleScriptLoadError (error: any): void {
    console.error('Script failed to load', error)
  }

  /**
   * Setup event listener to open live chat
   */
  useStullerEventListener('live-chat-open', handleLiveChatClick)

  return (
    <>
      {!unsupportedBrowser && !chatDisabled &&
        <Script
          id='live-chat-script'
          src={defaultChatSettings.externalScript}
          strategy='lazyOnload'
          onLoad={handleChatReady}
          onError={handleScriptLoadError}
        />}

      {!chatDisabled && (
        <div className={containerClass}>
          <div className='row justify-content-end'>
            <div className='col-auto'>
              {minimized
                ? (
                  <Button
                    color='info'
                    disabled={buttonDisabled}
                    hidden={buttonHidden}
                    onClick={handleLiveChatClick}
                    className={clsx(
                      marketRatesFooterShown ? 'pb-md-7' : 'pb-md-3',
                      'min-w-auto fw-normal pt-3 pb-2 ls-1 rounded-3 rounded-bottom-0 bg-cyan-500 border-bottom-0 border-cyan-600 border-blue-200-hover bottom-0 position-relative'
                    )}
                  >
                    <FontAwesomeIcon icon={fas.faComments} className='fs-4 text-white px-5 px-md-0' />
                    <span className='ms-3 d-none d-md-inline'>{buttonText}</span>
                    {unreadCount != null && (
                      <Badge color='orange-300' pill className='position-absolute top-0 start-0 translate-middle'>
                        {unreadCount}
                        <span className='visually-hidden'>unread messages</span>
                      </Badge>
                    )}
                  </Button>)
                : (
                  <Button
                    color='info'
                    disabled={buttonDisabled}
                    hidden={buttonHidden}
                    onClick={handleLiveChatClick}
                    pill
                    className='min-w-auto fw-normal py-3 ls-1 d-none d-md-inline-block'
                    style={{ marginTop: -100 }}
                  >
                    <FontAwesomeIcon icon={fas.faComments} className='me-3 fs-4 text-white' />
                    {buttonText}
                  </Button>)}
            </div>
          </div>
        </div>
      )}

      <Modal isOpen={modalOpen} onToggle={toggleModalOpen}>
        <ModalHeader>
          {unsupportedBrowser ? 'Browser Not Supported' : 'Live Chat is Temporarily Disabled'}
        </ModalHeader>
        <ModalBody>
          {unsupportedBrowser && 'This browser is not supported with our Live Chat feature.'}
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={toggleModalOpen}>Close</Button>
        </ModalFooter>
      </Modal>

      <style jsx>{`
        :global(.embeddedServiceSidebar.sidebarMinimized.modalContainer) {
          display: none;
        }

        @media (min-width: ${variables.breakpointMd}) {
          div.position-sticky {
            bottom: ${marketRatesFooterShown ? 35 : 0}px !important;
          }
        }
      `}
      </style>
    </>
  )
})
LiveChat.displayName = 'LiveChat'

export {
  LiveChat
}
