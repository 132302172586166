import { type ReactElement, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Alert, AlertHeading, AlertIcon } from '@stuller/stullercom/ui'
import { MenuCartContext } from './MenuCartContext'

/**
 * The error message for the cart popover when something goes wrong
 */
function CartError (): ReactElement {
  const { title } = useContext(MenuCartContext)

  return (
    <Alert color='danger' transparent className='mb-0'>
      <div className='row align-items-center justify-content-center mb-4'>
        <div className='col-auto'>
          <AlertIcon size='lg'>
            <FontAwesomeIcon icon={fas.faTriangleExclamation} />
          </AlertIcon>
        </div>
        <div className='col-auto'>
          <AlertHeading className='mb-0' data-test='unable-to-load-cart'>
            Unable to Load {title}
          </AlertHeading>
        </div>
      </div>
      <div className='row'>
        <div className='col text-center'>
          Sorry, we are experiencing some technical difficulties. Please try again later.
        </div>
      </div>
    </Alert>
  )
}

export {
  CartError
}
