import { forwardRef, useEffect } from 'react'
import {
  ProductCard,
  type ProductData,
  MultiItemCarousel,
  MultiItemCarouselInner,
  MultiItemCarouselControl,
  MultiItemCarouselIndicators,
  useMultiItemCarousel
} from '../../..'

export interface ProductMultiItemCarouselProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * List of product data to show in carousel
   */
  products: ProductData[]
  /**
   * Handler called when content is loaded (only gets called once ever)
   */
  onLoad?: () => void
}

/**
 * A component for displaying a list of [ProductCard](?path=/docs/products-productcard--docs)s inside of a [MultiItemCarousel](?path=/docs/components-multiitemcarousel--docs).
 */
const ProductMultiItemCarousel = forwardRef<HTMLDivElement, ProductMultiItemCarouselProps>(({
  id,
  className,
  products,
  onLoad,
  ...otherAttributes
}, ref) => {
  const multiItemCarouselProps = useMultiItemCarousel()

  useEffect(() => {
    onLoad?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MultiItemCarousel
      id={id}
      className={className}
      ref={ref}
      {...multiItemCarouselProps}
      {...otherAttributes}
    >
      <MultiItemCarouselInner className='pb-4 pb-md-0'>
        {products.map((product) =>
          <ProductCard
            key={product.id}
            className='flex-shrink-0'
            {...product}
          />
        )}
      </MultiItemCarouselInner>
      <MultiItemCarouselControl direction='prev' />
      <MultiItemCarouselControl direction='next' />
      <MultiItemCarouselIndicators />
    </MultiItemCarousel>
  )
})
ProductMultiItemCarousel.displayName = 'ProductMultiItemCarousel'

export {
  ProductMultiItemCarousel
}
