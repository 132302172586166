import type { NextPage } from 'next'
import { type ErrorProps as NextErrorProps } from 'next/error'
import { datadogRum } from '@datadog/browser-rum'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { Error403 } from './Error403'
import { Error404 } from './Error404'
import { Error500 } from './Error500'

export interface ErrorProps extends NextErrorProps {
  /**
   * The error thrown passed
   */
  err?: Error
  /**
   * Indicates getInitialProps has already run
   */
  hasGetInitialPropsRun?: boolean
}

const Error: NextPage<ErrorProps> = ({ statusCode, hasGetInitialPropsRun, err }) => {
  // Send client-side error to Datadog
  if (hasGetInitialPropsRun !== true && err != null) {
    datadogRum.addError(err)
    logger.error('Error: NextPage', { statusCode }, err)
  }

  if (statusCode === 404) {
    return <Error404 />
  } else if (statusCode === 403) {
    return <Error403 />
  }

  return <Error500 />
}

export {
  Error
}
