import { type ReactElement, type ReactNode, useMemo } from 'react'
import {
  type Middleware,
  offset,
  shift
} from '@floating-ui/react'
import { Popover, PopoverBody, type PopoverProps } from '@stuller/stullercom/ui'

export interface MenuPopoverProps extends Pick<PopoverProps, 'open' | 'onOpenChange' | 'reference'> {
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * Menu popover to show menu item details
 * Note that the popover body has 0 padding to allow the menu item to customize
 */
function MenuPopover ({
  children,
  open,
  onOpenChange,
  reference
}: MenuPopoverProps): ReactElement {
  const middleware = useMemo<Middleware[]>(() => ([
    offset(0),
    shift()
  ]), [])

  return (
    <Popover
      reference={reference}
      open={open}
      onOpenChange={onOpenChange}
      dismiss
      arrow
      placement='bottom-start'
      middleware={middleware}
      style={{
        maxWidth: 380,
        width: '100vw'
      }}
      className='bg-gray-100 rounded-5 rounded-top-0 border-0'
      arrowStyle={{
        '--bs-popover-arrow-border': 'var(--bs-blue-400)',
        '--bs-popover-bg': 'var(--bs-blue-400)',
        '--bs-popover-arrow-width': '32px',
        '--bs-popover-arrow-height': '8px',
        transform: 'rotate(180deg)',
        marginTop: 9
      }}
    >
      <PopoverBody className='p-0'>
        {children}
      </PopoverBody>
    </Popover>
  )
}

export {
  MenuPopover
}
