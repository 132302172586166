import { type ReactElement, useMemo } from 'react'
import clsx from 'clsx'
import { type Middleware, shift, useDelayGroupContext } from '@floating-ui/react'
import { Popover } from '@stuller/stullercom/ui'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTransition } from 'transition-hook'
import { type MainLink } from './links'
import { MainLinksContent } from './MainLinksContent'
import { PopoverSublinks } from './PopoverSublinks'

interface MainLinksPopoverProps extends MainLink {
  /**
   * Whether the popover is open
   */
  open: boolean
  /**
   * Function to set the open state
   */
  onOpenChange: (open: boolean) => void
  /**
   * Reference element for the popover
   */
  reference: HTMLAnchorElement | null
}

/**
 * Popover for the main navigation
 */
function MainLinksPopover ({
  open,
  onOpenChange,
  reference,
  label,
  url,
  contentContainerId,
  contentContainerName,
  sublinks
}: MainLinksPopoverProps): ReactElement {
  const { isShowcase } = useAuth()
  const sublinksResult = sublinks?.()
  const { stage } = useTransition(open, 200)
  const { delay, isInstantPhase } = useDelayGroupContext()
  const instantDelay = typeof delay !== 'number' ? delay.open === 1 : delay === 1
  const popoverShown = stage === 'enter' || instantDelay || isInstantPhase
  const classNames = clsx(
    'mw-100 vw-100 text-white border-0 rounded-0',
    isShowcase ? 'bg-gray-800' : 'bg-cyan-700'
  )
  const arrowColorVar = isShowcase ? '--bs-gray-700' : '--bs-cyan-500'
  const middleware = useMemo<Middleware[]>(() => ([
    shift({ padding: 0 })
  ]), [])

  return (
    <Popover
      open={open}
      onOpenChange={onOpenChange}
      reference={reference}
      hover
      arrow
      arrowStyle={{
        '--bs-popover-arrow-border': `var(${arrowColorVar})`,
        '--bs-popover-bg': `var(${arrowColorVar})`,
        '--bs-popover-arrow-width': '61px',
        '--bs-popover-arrow-height': '15px',
        marginTop: 16,
        transform: 'rotate(180deg) translateY(0)'
      }}
      flip={false}
      offset={0}
      className={classNames}
      hoverProps={{ delay }}
      delayGroup
      middleware={middleware}
      style={{
        boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.4), rgba(0, 0, 0, .15) 0px 30px 15px -15px inset',
        opacity: popoverShown ? 1 : 0,
        transform: popoverShown ? 'translateY(0)' : 'translateY(-30px)'
      }}
      data-gtm-click-subsection1={label}
    >
      <div
        className='container-xxxl transition-opacity'
        style={{ opacity: stage === 'enter' ? 1 : 0 }}
      >
        <div className='row align-items-stretch' style={{ minHeight: 500 }}>
          {sublinksResult != null
            ? <PopoverSublinks sublinksResult={sublinksResult} />
            : (
              <div className='col p-0'>
                <div className='row w-100 h-100 g-0'>
                  <MainLinksContent
                    label={label}
                    url={url}
                    contentContainerId={contentContainerId}
                    contentContainerName={contentContainerName}
                  />
                </div>
              </div>)}
        </div>
      </div>
    </Popover>
  )
}

export {
  MainLinksPopover
}
